<template>
  <div class="header">
    <div class="header-container">
      <h1>{{ title }}</h1>
      <div class="container">
        <div class="report" v-if="isAuthorized" @click="handleFullReport(); getReport();"><span>Report</span></div>
        <div v-if="isAuthorized" @click="onProfileButtonClick(); getProfile();" id="myProfile">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
<path d="M18 4.275C20.61 4.275 22.725 6.39 22.725 9C22.725 11.61 20.61 13.725 18 13.725C15.39 13.725 13.275 11.61 13.275 9C13.275 6.39 15.39 4.275 18 4.275ZM18 24.525C24.6825 24.525 31.725 27.81 31.725 29.25V31.725H4.275V29.25C4.275 27.81 11.3175 24.525 18 24.525ZM18 0C13.0275 0 9 4.0275 9 9C9 13.9725 13.0275 18 18 18C22.9725 18 27 13.9725 27 9C27 4.0275 22.9725 0 18 0ZM18 20.25C11.9925 20.25 0 23.265 0 29.25V33.75C0 34.9875 1.0125 36 2.25 36H33.75C34.9875 36 36 34.9875 36 33.75V29.25C36 23.265 24.0075 20.25 18 20.25Z" fill="#40785A"/>
</svg>
          <span>My Profile</span>
        </div>
        <div v-if="isAuthorized" @click="onLogutButtonClick" id="logout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="37"
          viewBox="0 0 36 37"
          fill="none"
        >
          <path
            d="M4 4.54106H16C17.1 4.54106 18 3.64106 18 2.54106C18 1.44106 17.1 0.541061 16 0.541061H4C1.8 0.541061 0 2.34106 0 4.54106V32.5411C0 34.7411 1.8 36.5411 4 36.5411H16C17.1 36.5411 18 35.6411 18 34.5411C18 33.4411 17.1 32.5411 16 32.5411H4V4.54106Z"
            fill="#BC4242"
          />
          <path
            d="M35.3 17.8411L29.72 12.2611C29.08 11.6211 28 12.0611 28 12.9611V16.5411H14C12.9 16.5411 12 17.4411 12 18.5411C12 19.6411 12.9 20.5411 14 20.5411H28V24.1211C28 25.0211 29.08 25.4611 29.7 24.8211L35.28 19.2411C35.68 18.8611 35.68 18.2211 35.3 17.8411Z"
            fill="#BC4242"
          />
        </svg>
        <span>Logout</span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: true
    },
    isAuthorized: {
      type: Boolean,
      required: true
    },
    onLogutButtonClick: {
      type: Function,
      required: true
    },
    onProfileButtonClick: {
      type: Function,
      required: true
    },
    handleFullReport: {
      type: Function,
      required: true
    }
  },
  methods: {
    async getProfile(){
      try {
         await this.$store.dispatch('LOAD_OPERATORINFO')
      } catch (err) {
        throw new Error(err);
      }
    },
    async getReport(){
      try {
         await this.$store.dispatch('GET_REPORTINFO')
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  async created(){
    await this.$store.dispatch('LOAD_OPERATORINFO')
  },
};
</script>

<style>
.header {
  width: 100%;
  height: 96px;
  background: #3f4648;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
}

.header-container {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a3a8a9;
}

#logout {
  height: 36px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.container {
  display: flex;
}

#myProfile {
  height: 36px;
  width: 120px;
   display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 20px;
}
.report {
  background: #455D99;
  border-radius: 4px;
  font-size:18px;
  color:#D7D7D7;
  width:98px;
  height:36px;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right:24px;
  cursor:pointer;
}
</style>
