import axios from '@/utils/axiosInstance'
import moment from "moment";

const operatorInfo = {
    state: {
        userName: "",
        fullName: "",
        mobileNumber: "",
        email: "",
        residence: "",
        role: "",
        accent: "",
        gender: "",
        dataOfBirth: "",
        createDate: ""
    },
    mutations: {
        SET_USERNAME(state, data) {
            state.userName = data;
        },
        SET_FULLNAME(state, data) {
            state.fullName = data;
        },
        SET_MOBILENUMBER(state, data) {
            state.mobileNumber = data;
        },
        SET_EMAIL(state, data) {
            state.email = data;
        },
        SET_RESIDENCE(state, data) {
            state.residence = data;
        },
        SET_ROLE(state, data) {
            state.role = data;
        },
        SET_ACCENT(state, data) {
            state.accent = data;
        },
        SET_GENDER(state, data) {
            state.gender = data;
        },
        SET_DATAOFBIRTH(state, data) {
            state.dataOfBirth = data;
        },
        SET_CREATEDATE(state, data) {
            state.createDate = data;
        },

        
    },
    actions: {
        async LOAD_OPERATORINFO({ commit }) {
            try {
                const res = await axios.get('/api/Account/MyProfile');
                commit('SET_USERNAME', res.data.userName)
                commit('SET_FULLNAME', res.data.fullName)
                commit('SET_MOBILENUMBER', res.data.mobileNumber)
                commit('SET_EMAIL', res.data.email)
                commit('SET_RESIDENCE', res.data.residence)
                commit('SET_MOBILENUMBER', res.data.mobileNumber)
                commit('SET_ROLE', res.data.role)
                commit('SET_ACCENT', res.data.accent)
                if(res.data.gender === 1){
                    commit('SET_GENDER', "Male")
                }
                else if(res.data.gender === 2){
                    commit('SET_GENDER', "Female")
                }
                commit('SET_DATAOFBIRTH', moment(res.data.dataOfBirth).format('MMMM Do YYYY, h:mm:ss a'))
                commit('SET_CREATEDATE', moment(res.data.createDate).format('MMMM Do YYYY, h:mm:ss a'))
            } catch (err) {
                throw new Error(err);
            }
        },
        async SAVEOPERATORINFO(payload){
            try {
                const res = await axios.post('/api/Operator/EditMyProfile', payload);
                console.log(res)
              } catch (err) {
                throw new Error(err);
              }
        }

    },
    getters: {
    },
}

export default operatorInfo;
