import axios from '@/utils/axiosInstance'
const moderator = {
    state: {
        operatorWorkSchedule: [],
    },
    mutations: {
        SET_OPERATORINFO(state, data) {
            state.operatorWorkSchedule = data;
        },
    },
    actions: {
        async LOAD_WORKSCHEDULE({ commit }) {
            try {
                const res = await axios.get("/api/Moderator/GetAllWorkSchedule");
                commit('SET_OPERATORINFO', res.data)
            } catch (err) {
                throw new Error(err);
            }
        },
    },
    getters: {},
    //  namespaced: true,
}
export default moderator;