<template>
  <div class="box">
      <div class="container">
          <div class="modal">
              <div class="modal-header">
                  <div>xxxxxxx</div>
                  <div class="flex">
                      <input class="input" placeholder="SEARCH"/>
                      <div class="close" @click="closeLidBox">X</div>
                  </div>
              </div>
              <div class="items-box-container"> 
                  <div class="items-box">
                      <div class="items-box-header">
                          <div>Item 1</div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
<path d="M11.8546 0.353036L6.99374 5.03604L2.13289 0.353036C1.6443 -0.117679 0.855034 -0.117679 0.366443 0.353036C-0.122148 0.82375 -0.122148 1.58413 0.366443 2.05485L6.11678 7.59479C6.60537 8.0655 7.39463 8.0655 7.88322 7.59479L13.6336 2.05485C14.1221 1.58413 14.1221 0.82375 13.6336 0.353036C13.145 -0.105609 12.3432 -0.117679 11.8546 0.353036Z" fill="#C4C8DF"/>
</svg>
                      </div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                  </div>
                  <div class="items-box">
                      <div class="items-box-header">
                          <div>Item 1</div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
<path d="M11.8546 0.353036L6.99374 5.03604L2.13289 0.353036C1.6443 -0.117679 0.855034 -0.117679 0.366443 0.353036C-0.122148 0.82375 -0.122148 1.58413 0.366443 2.05485L6.11678 7.59479C6.60537 8.0655 7.39463 8.0655 7.88322 7.59479L13.6336 2.05485C14.1221 1.58413 14.1221 0.82375 13.6336 0.353036C13.145 -0.105609 12.3432 -0.117679 11.8546 0.353036Z" fill="#C4C8DF"/>
</svg>
                      </div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                      <div class="item">Item 1</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: ["closeLidBox"],
}
</script>

<style>
.box{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top:0;
    left: 0;
    z-index: 5;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.modal{
    width: 708px;
    height: 458px;
    background-color: #0E0E0F;
    color: #C4C8DF;
}

.modal-header{
    height: 70px;
    background-color:#1B1B1C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
}

.input{
    width: 196px;
    height: 40px;
    border: 2px solid black;
    padding: 0 15px;
    background-color: #1B1B1C;
    margin-right: 15px;
    color: #C4C8DF;
}

.items-box{
    width: 318px;
    height: 282px;
    background: #040404;
    margin: 24px;
    overflow: scroll;
}

.items-box-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #1B1B1C;
    padding: 0 15px;
    margin-bottom: 10px;
}

.item{
    height: 36px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.item:hover {
    background: #1B1B1C;

}

.items-box-container{
    display: flex;
}

.flex{
    display: flex;
    align-items: center;
}

.close{
    cursor: pointer;
}
</style>