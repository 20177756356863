import * as signalR from '@aspnet/signalr'
export default {
    install(Vue) {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`http://95.216.40.62:88/NotifyModerators`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        const moderatorHub = new Vue();

        Vue.prototype.$moderatorHub = moderatorHub;

        let startedPromise = null;


        function start() {
            startedPromise = connection.start()
                .catch(err => {
                    console.error('Failed to connect with hub', err)
                        // return new Promise((resolve, reject) =>
                        //     setTimeout(
                        //         () =>
                        //         start()
                        //         .then(resolve)
                        //         .catch(reject),
                        //         5000
                        //     )
                        // );
                });
            return startedPromise;
        }
        // connection.onclose(() => start());

        connection.on("RequestModerators", (data) => {
            moderatorHub.$emit('need-help', { data })
        });

        connection.on("UpdateModerator", (dato) => {
            moderatorHub.$emit('update-table', { dato })
        });
        Vue.prototype.$connStart = function() {
            start()
        }
        Vue.prototype.$connStop = function() {
            connection.stop();
        }

    }
};