import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import user from './modules/user.js'
import leadInfo from './modules/leadInfo.js';
import operatorInfo from './modules/operatorInfo.js'
import moderator from './modules/moderator.js';
import reportInfo from './modules/reportInfo.js';
import questionsNAnswers from './modules/questionsAndAnswers.js';

export const store = new Vuex.Store({
    modules: {
        user,
        leadInfo,
        operatorInfo,
        moderator,
        reportInfo,
        questionsNAnswers
    },

    state: {

    },
    actions: {},
    mutations: {}
})