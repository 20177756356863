<template>
    <div>
        <div class="question-type"><slot></slot></div>
    </div>
</template>

<script>
export default {
  name: "QuestionsType",
  props: {

  },
  methods: {

  }
};
</script>

<style>
.question-type{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    color: black;
    border-radius: 15px;
    width: 200px;
    cursor: pointer;
    margin-bottom: 10px;
}

</style>
