<template>
  <form id="login" @keyup.enter="handleClickSignInButton(); getRole();">
    <input
      class="login-input"
      type="text"
      name="userName"
      v-model="input.userName"
      placeholder="Nickname"
    />
    <input
      class="login-input"
      type="password"
      name="password"
      v-model="input.password"
      placeholder="Password"
    />
    <div class="forgot-password">
      <div>
        <input type="checkbox" id="remember" name="remember" />
        <label for="remember">Remember</label>
      </div>
      <div>Forgot your password?</div>
    </div>
    <button @click="handleClickSignInButton();" id="login-button" type="button">
      LOG IN
    </button>
    
  </form>
</template>

<script>
export default {
  data() {
    return {
      input: {
        userName: "",
        password: "",
      },
      isFetched: false,
      organization: null,
    };
  },
  props: {
    onClickSignInButton: {
      type: Function,
      required: true
    },
  },
  methods: {
    handleClickSignInButton(){
        this.onClickSignInButton(this.input);
    }
  }
};
</script>

<style>
#login {
  width: 464px;
  height: 400px;
  background: #2e3335;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 50px;
}

.login-input {
  background: #141617;
  border-radius: 12px;
  width: 416px;
  height: 52px;
  color: white;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

input {
  outline: none;
}

#login-button {
  width: 416px;
  height: 52px;
  border-radius: 12px;
  background: #32655c;
  color: white;
  margin-left: 24px;
  margin-right: 24px;
  cursor: pointer;
}

.forgot-password {
  display: flex;
  justify-content: space-between;
  width: 416px;
  margin: 24px 24px;
  color: #c4c8df;
  font-size: 14px;
}
</style>