<template>
    <div class="box">
    <div class="container">
      <div class="modal">
        <div class="close-btn" @click="handleCloseBtnClick">X</div>
        <div>
            <div class="input-container">
                <label>Type</label>
                <input v-model="type"/>
            </div>
            <div class="input-container">
                <label>Question</label>
                <input v-model="question"/>
            </div>
            <div class="input-container">
                <label>Answer</label>
            <select v-model="answer">
                  <option>Yes</option>
                  <option>No</option>
            </select>
            </div>
            <div class="save-btn" @click="save">Save</div>
        </div>
      </div>    
    </div>
  </div>
</template>

<script>
import axios from "@/utils/axiosInstance";

export default {
  name: "AddQuestionType",
  props: {
      handleCloseBtnClick: {
          type: Function,
          required: true
      },
      closeModal: {
          type: Function,
          required: true
      }
  },
  data() {
    return {
      type: null,
      question: null,
      answer: null
    };
  },
  computed: {
      isAnswerYes(){
          if(this.answer === "Yes"){
              return true;
          }else{
              return false;
          }
      }
  },
  methods: {
      async save(){
          try{
        const res = await axios.post("/api/Questions/AddTreeType", {question: this.question, isAnswerYes: this.isAnswerYes, type: this.type});
        console.log(res);
        this.closeModal();
        }catch(err){
            throw new Error(err);
        }
      },
  },
};
</script>

<style scoped>
.box{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top:0;
    left: 0;
    z-index: 10;
}
.container{
    display: flex;
    justify-content: center;
    height: 100vh;
}
.modal{
    width: 600px;
    height: 400px;
    background-color: #0E0E0F;
    color: #C4C8DF;
    background-color: #2e3335;
    position: relative;
    padding: 15px;
}

.close-btn{
    width: 100%;
    display: flex;
    justify-content: end;
    cursor: pointer;
    font-size: 25px;
}

input{
    width: 350px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    font-size: 20px;
}

.input-container{
    width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 20px 40px;

}

select{
    width: 355px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    font-size: 20px;
}

.save-btn{
    width: 100px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 25px;
    margin: 0 auto;
    margin-top: 50px;
    cursor: pointer;
}
</style>