<template>
  <div class="container">
    <div class="content">
      <div class="clsBtn" @click="closeIndReport">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M17.75 2.0125L15.9875 0.25L9 7.2375L2.0125 0.25L0.25 2.0125L7.2375 9L0.25 15.9875L2.0125 17.75L9 10.7625L15.9875 17.75L17.75 15.9875L10.7625 9L17.75 2.0125Z"
            fill="#A3A8A9"
          />
        </svg>
      </div>
      <div class="searchBar">
        <div>{{ myUserName }}</div>
        <div class="leftSide">
          <div>
            <input type="date" v-model="startDate" />
            <input type="date" v-model="endDate" />
          </div>
          <div class="myReport" @click="filterByTime">Submit</div>
        </div>
      </div>
      <table class="table" id="indContent">
        <thead>
          <tr>
            <th class="pdfText">Date\State</th>
            <th class="pdfText">Available</th>
            <th class="pdfText">Busy</th>
            <th class="pdfText">Break</th>
            <th class="pdfText">NotPresent</th>
            <th class="pdfText">OnCall</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in indReport" :key="data.operatorId">
            <td class="pdfText">{{ data.userName }}</td>
            <td class="pdfText">{{ data.statesDuration.Available }}</td>
            <td class="pdfText">{{ data.statesDuration.Busy }}</td>
            <td class="pdfText">{{ data.statesDuration.Break }}</td>
            <td class="pdfText">{{ data.statesDuration.NotPresent }}</td>
            <td class="pdfText">{{ data.statesDuration.OnCall }}</td>
          </tr>
        </tbody>
      </table>
      <div class="saveInfo" @click="saveIndInfo">
        <span>Save Info</span>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
          >
            <circle cx="18" cy="18" r="17.5" stroke="#42BC73" />
            <path
              d="M23 9H11C9.89 9 9 9.9 9 11V25C9 26.1 9.89 27 11 27H25C26.1 27 27 26.1 27 25V13L23 9ZM25 25H11V11H22.17L25 13.83V25ZM18 18C16.34 18 15 19.34 15 21C15 22.66 16.34 24 18 24C19.66 24 21 22.66 21 21C21 19.34 19.66 18 18 18ZM12 12H21V16H12V12Z"
              fill="#42BC73"
            />
          </svg>
        </div>
      </div>
      <div class="back" @click="goBack">Back</div>
    </div>
  </div>
</template>
<script>


export default {
  name: "IndReport",
  data() {
    return {
      startDate: null,
      endDate: null,
    };
  },
  props: {
    closeIndReport: {
      type: Function,
      required: true,
    },
    goBack: {
      type: Function,
      required: true,
    },
    saveIndInfo: {
      type: Function,
      required: true,
    },
  },
  computed: {
    indReport() {
      return this.$store.state.reportInfo.indReportInfo;
    },
    myUserName() {
      return this.$store.state.reportInfo.indReportInfo.userName;
    },
  },
  methods: {
    async filterByTime() {
      try {
        await this.$store.dispatch("GET_INDREPORTINFO", this.startDate, this.endDate, null);
      } catch (err) {
        throw new Error(err);
      }
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 1220px;
  height: 740px;
  background-color: #1b1b1c;
  overflow-y: scroll;
  position: relative;
}
.clsBtn {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.pdfText {
  color:orangered;
}
.searchBar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7b7b8a;
  /* margin: 0 20px; */
  font-size: 24px;
  width: 90%;
  margin: 0 auto;
}
.leftSide {
  display: flex;
  flex-direction: row;
}
.details {
  color: #4b6fc9;
}
input {
  width: 180px;
  height: 30px;
  background-color: transparent;
  outline: none;
  border: 2px solid #455d99;
  border-radius: 4px;
  margin-right: 12px;
  padding: 0 10px;
  color: #fff;
}
.myReport {
  width: 200px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d7d7d7;
  background-color: #455d99;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
}
.table {
  width: 90%;
  border: 2px solid #3f4648;
  border-collapse: collapse;
  color: #fff;
  margin: 0 auto;
  margin-top: 10px;
}
.table th {
  border: 2px solid #3f4648;
  font-size: 20px;
  height: 30px;
}
.table td {
  border: 2px solid #3f4648;
  height: 40px;
  text-align: center;
}
.saveInfo {
  width: 120px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  color: #fff;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.back {
  position: absolute;
  left: 50px;
  bottom: 25px;
  color: #c4c8df;
  cursor: pointer;
  font-size: 18px;
}
</style>