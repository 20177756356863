<template>
  <div class="container">
    <div class="content">
      <div class="clsBtn" @click="closeFullReport">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M17.75 2.0125L15.9875 0.25L9 7.2375L2.0125 0.25L0.25 2.0125L7.2375 9L0.25 15.9875L2.0125 17.75L9 10.7625L15.9875 17.75L17.75 15.9875L10.7625 9L17.75 2.0125Z"
            fill="#A3A8A9"
          />
        </svg>
      </div>
      <div class="searchBar">
        <div>Full Report</div>
        <div class="leftSide">
          <div>
            <input type="text" placeholder="UserName" v-model="userName" />
          </div>
          <div class="myReport" @click="ownReport(null, null, null)">
            My Report
          </div>
        </div>
      </div>
      <table class="table" id="content">
        <thead>
          <tr>
            <th class="pdfText">Name\State</th>
            <th class="pdfText">Available</th>
            <th class="pdfText">Busy</th>
            <th class="pdfText">Break</th>
            <th class="pdfText">NotPresent</th>
            <th class="pdfText">OnCall</th>
            <th class="pdfText">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="data in operatorList" :key="data.operatorId">
            <td class="pdfText">{{ data.userName }}</td>
            <td class="pdfText">{{ data.statesDuration.Available }}</td>
            <td class="pdfText">{{ data.statesDuration.Busy }}</td>
            <td class="pdfText">{{ data.statesDuration.Break }}</td>
            <td class="pdfText">{{ data.statesDuration.NotPresent }}</td>
            <td class="pdfText">{{ data.statesDuration.OnCall }}</td>
            <td class="details" @click="ownReport(null, null, data.operatorId)">
              Details
            </td>
          </tr>
        </tbody>
      </table>
      <!-- </section>
      </vue-html2pdf> -->
      <div
        class="saveInfo"
        @click="
          download();
          saveInfo();
        "
      >
        <span>Save Info</span>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
          >
            <circle cx="18" cy="18" r="17.5" stroke="#42BC73" />
            <path
              d="M23 9H11C9.89 9 9 9.9 9 11V25C9 26.1 9.89 27 11 27H25C26.1 27 27 26.1 27 25V13L23 9ZM25 25H11V11H22.17L25 13.83V25ZM18 18C16.34 18 15 19.34 15 21C15 22.66 16.34 24 18 24C19.66 24 21 22.66 21 21C21 19.34 19.66 18 18 18ZM12 12H21V16H12V12Z"
              fill="#42BC73"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import html2canvas from "html2canvas";
import jsPDF from 'jspdf'


export default {
    name: "FullReport",
    data(){
        return {
            userName: null,
        }
    },
    props: {
    closeFullReport: {
      type: Function,
      required: true
    },
    ownReport: {
      type: Function,
      required: true
    },
    saveInfo: {
      type: Function,
      required: true       
    }
    },
    computed: {
        report(){
        return this.$store.state.reportInfo.reportInfo;
        },
        operatorList(){
            if(this.userName === null){
                return this.report;
            }else{
                return this.report.filter(operator => operator.userName.toLowerCase().includes(this.userName.toLowerCase()))
            }
        }
    },
    methods: {
      download() {
        window.html2canvas = html2canvas;
        var doc = new jsPDF("p","pt","a4");
        doc.html(document.querySelector("#content"),{
          callback: function(pdf) {
            pdf.save("report.pdf");
          }
        })
      } 
    },
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 1220px;
  height: 740px;
  background-color: #1b1b1c;
  overflow-y: scroll;
  position: relative;
}
.clsBtn {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.searchBar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7b7b8a;
  /* margin: 0 20px; */
  font-size: 24px;
  width: 90%;
  margin: 0 auto;
}
.pdfText {
  color:orangered;
}
.leftSide {
  display: flex;
  flex-direction: row;
}
.details {
  color: #4b6fc9;
  cursor: pointer;
}
input {
  width: 180px;
  height: 30px;
  background-color: transparent;
  outline: none;
  border: 2px solid #455d99;
  border-radius: 4px;
  margin-right: 12px;
  padding: 0 10px;
  color: #fff;
}
.myReport {
  width: 200px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d7d7d7;
  background-color: #455d99;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
}
.table {
  width: 90%;
  border: 2px solid #3f4648;
  border-collapse: collapse;
  color: #fff;
  margin: 0 auto;
  margin-top: 10px;
}
.table th {
  border: 2px solid #3f4648;
  font-size: 20px;
  height: 30px;
}
.table td {
  border: 2px solid #3f4648;
  height: 40px;
  text-align: center;
}
.saveInfo {
  width: 120px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  color: #fff;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>