<template>
  <div class="main">
    <div class="time">
      <div>{{ availableFormattedElapsedTime }}</div>
      <div>{{ busyFormattedElapsedTime }}</div>
      <div>{{ notPresentFormattedElapsedTime }}</div>
      <div>{{ breakFormattedElapsedTime }}</div>
    </div>
    <div class="main-container">
      <div class="col">
        <div
          class="circle green"
          id="available"
          :class="{ active: activeStatuses.includes(1) }"
          @click="
            sendRequest('/api/Operator/OperatorStartWork');
            timerHandler(
              availableStart(),

              busyStop(),
              notPresentStop(),
              breakStop()
            );
          "
        >
          <div class="options green-title"><span>READY TO WORK</span></div>
        </div>
        <div
          class="circle yellow"
          id="busy"
          :class="{ active: activeStatuses.includes(2) }"
          @click="
            sendRequest('/api/Operator/OperatorGoBusy');
            timerHandler(
              busyStart(),
              availableStop(),
              notPresentStop(),
              breakStop()
            );
          "
        >
          <div class="options yellow-title"><span>NEED TIME</span></div>
        </div>
        <div
          class="circle red"
          id="notPresent"
          :class="{ active: activeStatuses.includes(4) }"
          @click="
            sendRequest('/api/Operator/OperatorEndWork');
            timerHandler(
              notPresentStart(),
              availableStop(),
              busyStop(),
              breakStop()
            );
          "
        >
          <div class="options red-title"><span>WORK DONE</span></div>
        </div>
        <div
          class="circle blue"
          id="break"
          :class="{ active: activeStatuses.includes(3) }"
          @click="
            sendRequest('/api/Operator/OperatorStartBreak');
            timerHandler(
              breakStart(),
              availableStop(),
              notPresentStop(),
              busyStop()
            );
          "
        >
          <div class="options blue-title"><span>BREAK TIME</span></div>
        </div>
        <div class="payDay" @click="handlePay" v-if="isModerator">Pay Day</div>
        <div class="payModal" v-show="showPaySett">
            <div class="modalClsBtn" @click="clsModal"><svg  xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M17.75 2.0125L15.9875 0.25L9 7.2375L2.0125 0.25L0.25 2.0125L7.2375 9L0.25 15.9875L2.0125 17.75L9 10.7625L15.9875 17.75L17.75 15.9875L10.7625 9L17.75 2.0125Z" fill="#A3A8A9"/>
</svg></div>
          <div class="pay" @click="payDone">Pay</div>
          <div class="editpay" @click="handleShowDate">Edit Payday</div>
          <div class="calendar" v-show="showDate">
            <input type="date" v-model="payTimeEdit">
            <div class="submit" @click="submitEdit">submit</div>
          </div>
        </div>
      </div>
      <slot></slot>
      <OperatorWorkSchedule v-if="isModerator"/>
      <div class="box" v-if="showLeadBox">
        <div class="container">
          <div class="modal">
            <div class="modal-header">
              <h2>Lead Information</h2>
              <div @click="closeBtn" class="clsBtn">X</div>
            </div>
            <div class="info">
              <div class="info-container-1">
                <img :src="this.$store.state.leadInfo.src" class="pic" />
                <div class="title">Personal Info</div>
                <div>Full Name: {{ this.$store.state.leadInfo.fullNameL }}</div>
                <div class="space-between">
                  <label>Nickname:</label>
                  <input class="lead-input" v-model="nickName" />
                </div>
                <div>ID Number: {{ this.$store.state.leadInfo.idNumber }}</div>
                <div>Gender: {{ this.$store.state.leadInfo.genderL }}</div>
                <div>Age: {{ this.$store.state.leadInfo.age }}</div>
                <div>
                  Date Of Birth: {{ this.$store.state.leadInfo.dateOfBirthL }}
                </div>
                <div>Eye Color: {{ this.$store.state.leadInfo.eyeColor }}</div>
                <label>Hair Color:</label>
                <select
                  class="select"
                  v-model="this.$store.state.leadInfo.hairColor"
                >
                  <option>Black</option>
                  <option>Green</option>
                  <option>Brown</option>
                </select>
                <div>Height: {{ this.$store.state.leadInfo.height }} cm</div>
                <label>Weight:</label>
                <input
                  class="lead-input weight-input"
                  v-model="this.$store.state.leadInfo.weight"
                />
                <span>kg</span>
                <div>
                  Nationality: {{ this.$store.state.leadInfo.nationality }}
                </div>
                <div>Accent: {{ this.$store.state.leadInfo.accentL }}</div>
                <div>
                  <label>Profession:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.profession"
                  />
                </div>
                <div class="title">Contact Info</div>
                <div>
                  Mobile Number: {{ this.$store.state.leadInfo.mobileNumberL }}
                </div>
                <label>Email:</label>
                <input
                  class="lead-input"
                  type="email"
                  v-model="this.$store.state.leadInfo.emailL"
                />
                <div>
                  <label>Address:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.address"
                  />
                </div>
                <div>
                  <label>Residence:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.residenceL"
                  />
                </div>
                <div>
                  <label>Facebook:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.facebook"
                  />
                </div>
              </div>
              <div class="info-container">
                <div>
                  <label>Experience:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.experience"
                  />
                </div>
                <div>
                  <label>Financial Condition:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.financialCondition"
                  />
                </div>
                <div>
                  <label>Money Transfered Amount:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.moneyTransferedAmount"
                  />
                </div>
                <div>Documents: <a :href="this.$store.state.leadInfo.documents">To see documents click here!</a></div>
                <div>Is Called: {{ this.$store.state.leadInfo.isCalled }}</div>
                <div>
                  <label>Call FeedBack:</label>
                  <select
                    class="select"
                    v-model="this.$store.state.leadInfo.callFeedBack"
                  >
                    <option>No Answer</option>
                    <option>Answered But Not Interested</option>
                    <option>Interested But Not Agreed</option>
                    <option>Answered And Paid</option>
                    <option>Answered And Paid To Retention</option>
                    <option>Real Lead</option>
                  </select>
                </div>
                <div>Call Date: {{ this.$store.state.leadInfo.callDate }}</div>
                <div>
                  <label>Operator ID:</label>
                  <input
                    class="lead-input"
                    v-model="this.$store.state.leadInfo.operatorId"
                  />
                </div>
<!--                 <div>
                  Default Sum: {{ this.$store.state.leadInfo.defaultSum }}
                </div>
                <div>
                  Special Sum: {{ this.$store.state.leadInfo.specialSum }}
                </div>
                <div>
                  Sum:
                  <input class="lead-input" v-model.lazy="sum" />
                </div> -->
                <div class="about-me">
                  <label>About Me:</label>
                  <textarea 
                    v-model="this.$store.state.leadInfo.aboutMe"
                  ></textarea>
                </div>
<!--                <div class="questions">
                  <div class="questions-header"><h3>Questions/Answers</h3></div>
                  <ul>
                    <li
                      v-for="(questionAndAnswer, index) in questionAndAnswers"
                      :key="questionAndAnswer.answer.id"
                      class="quetions-answers"
                    >
                      <div>
                        Q{{ index + 1 }}.
                        {{ questionAndAnswer.question.question }}
                      </div>
                      <input
                        class="answer"
                        v-model="questionAndAnswer.answer.answer"
                      />
                    </li>
                  </ul>
                </div>  -->
                <div class="organizations">
                  <select
                  class="select-org"
                  v-model="organization"
                  >
                  <option v-for="organization in organizations" :key="organization.uniqueId">{{organization.name}}</option>
                </select>
                  <button class="org-btn" @click="organizationSubmit">Submit</button>
                </div>
                 <div v-if="showSomeInfo">
                  Default Sum: {{ this.$store.state.leadInfo.defaultSum }}
                </div>
                <div v-if="showSomeInfo">
                  Special Sum: {{ this.$store.state.leadInfo.specialSum }}
                </div>
                <div v-if="showSomeInfo">
                  Sum:
                  <input class="lead-input" v-model.lazy="sum" />
                </div>
                <!-- <div v-if="showSomeInfo" class="questions">
                  <div class="questions-header"><h3>Questions/Answers</h3></div>
                  <ul>
                    <li
                      v-for="(questionAndAnswer, index) in questionAndAnswers"
                      :key="questionAndAnswer.answer.id"
                      class="quetions-answers"
                    >
                      <div>
                        Q{{ index + 1 }}.
                        {{ questionAndAnswer.question.question }}
                      </div>
                      <input
                        class="answer"
                        v-model="questionAndAnswer.answer.answer"
                      />
                    </li>
                  </ul>
                </div> -->
              <div>
                <div class="title">Questions and Answars</div>
                <QuestionsType @click.native="showAddQuestType">Add Question Type</QuestionsType>
                <div class="grid">
                  <div class="questions-container" v-for="type in questionsTypes" :key="type">
                  <QuestionsType @click.native="getQuestionsAndAnswers(type); showQuestionsAndAnswers()">{{type}}</QuestionsType>
                </div>
                </div>
              </div>  
              </div>
              <div class="save-lead-info" @click="saveLeadInfo">Save</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="box" v-if="showError">
          <div class="container">
            <div class="err-modal">
              <div class="err-modal-header">
                <div></div>
                <div @click="closeErr" class="clsBtn">X</div>
              </div>
              <div class="err-message">Incorrect Phone Number!</div>
            </div>
          </div>
        </div>
      </div>
      <div class="getLead">
        <input
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          v-model="mobileNumber"
          class="telInput"
          placeholder="123 - 456 - 789"
        />
        <div class="getLeadBtn" @click="getLeadInfo()">Get Lead</div>
      </div>
      <div class="help-request" @click="sendHelpRequest" v-if="!isModerator">
        Help request
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <rect
            x="1"
            y="1"
            width="34"
            height="34"
            rx="17"
            stroke="#40785A"
            stroke-width="2"
          />
          <path
            d="M25.25 14C24.56 14 24 14.56 24 15.25V21H23.5C21.85 21 20.5 22.35 20.5 24H19.5C19.5 21.96 21.03 20.28 23 20.03V9.25C23 8.56 22.44 8 21.75 8C21.06 8 20.5 8.56 20.5 9.25V17H19.5V7.25C19.5 6.56 18.94 6 18.25 6C17.56 6 17 6.56 17 7.25V17H16V8.75C16 8.06 15.44 7.5 14.75 7.5C14.06 7.5 13.5 8.06 13.5 8.75V18H12.5V11.75C12.5 11.06 11.94 10.5 11.25 10.5C10.56 10.5 10 11.06 10 11.75V21.75C10 26.31 13.69 30 18.25 30C22.81 30 26.5 26.31 26.5 21.75V15.25C26.5 14.56 25.94 14 25.25 14Z"
            fill="#40785A"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
// const STATUSES = {
//   1: 1, //available
//   2: 2, //busy
//   3: 3, //break
//   4: 4, //notPresent
// };

// const CONDITIONS = {
//   1: 1, //active
//   2: 2, //inactive
//   3: 3, //N/A
// };

import axios from "@/utils/axiosInstance";
import moment from "moment";
import { hairColorMap, callFeedbackMap } from "../constants";
import OperatorWorkSchedule from "./OperatorWorkSchedule";
import QuestionsType from "./QuestionsType.vue";

export default {
  components: {
     OperatorWorkSchedule,
     QuestionsType,
  },
  data() {
    return {
      showLeadBox: false,
      availableElapsedTime: moment("0001-01-01T00:00:00"),
      availableTimer: undefined,
      busyElapsedTime: moment("0001-01-01T00:00:00"),
      busyTimer: undefined,
      notPresentElapsedTime: moment("0001-01-01T00:00:00"),
      notPresentTimer: undefined,
      breakElapsedTime: moment("0001-01-01T00:00:00"),
      breakTimer: undefined,
      mobileNumber: "",
      timesArr: [],
      statuses: [],
      showError: false,
      sum: 0,
      organization: null,
      uniqueId: null,
      showSomeInfo: false,
      showPayDay: false,
      payTime: null,
      showPayDayEdit: false,
      payTimeEdit: null,
      showPaySett: false,
      showDate: false,
      questionType: null,
    };
  },
  props: {
    isModerator: {
      type: Boolean,
      required: true
    },
    onLidBoxButtonClick: {
      type: Function,
      required: true
    },
    showQuestionsAndAnswers: {
      type: Function,
      required: true
    },
    showAddQuestType: {
      type: Function,
      required: true
    }
  },
  computed: {
    organizations(){
      return this.$store.state.leadInfo.organizations;
    },
    questionAndAnswers() {
      return this.$store.state.leadInfo.questionAndAnswers;
    },
    nickName() {
      return this.$store.state.leadInfo.nickName;
    },
    questionsTypes(){
      return this.$store.state.leadInfo.types;
    },
    activeStatuses() {
      const activeStatuses = this.statuses.filter(
        (x) => x.condition === 1 || x.condition === 2
      );
      return activeStatuses.map(({ status }) => status);
    },

    availableFormattedElapsedTime() {
      return moment(this.availableElapsedTime).format("HH:mm:ss");
    },

    busyFormattedElapsedTime() {
      return moment(this.busyElapsedTime).format("HH:mm:ss");
    },
    notPresentFormattedElapsedTime() {
      return moment(this.notPresentElapsedTime).format("HH:mm:ss");
    },
    breakFormattedElapsedTime() {
      return moment(this.breakElapsedTime).format("HH:mm:ss");
    },
  },
  methods: {
    handlePay(){
      this.showPaySett = ! this.showPaySett;
      this.showDate = false;
    },
    clsModal() {
      this.showPaySett = false;
      this.showDate = false;
    },
    handleShowDate() {
      this.showDate = ! this.showDate;
    },
    async payDone() {
      try {
        const res = await axios.post("/api/PayDay/Pay");
        console.log(res);
      } catch (err) {
        throw new Error(err);
      }
      this.showPaySett = false;
    },
    async submitEdit() {
      try {
        const res = await axios.post("/api/PayDay/EditPayDate", {
          newPayDate: moment.utc(this.payTimeEdit).format()
        });
        console.log(res);
      } catch (err) {
        throw new Error(err);
      }
      this.showPaySett = false;
      this.showDate = false;
    },
    closeBtn() {
      this.showLeadBox = false;
    },
    closeErr() {
      this.showError = false;
    },
    async sendRequest(url) {
      try {
        const data = await axios.post(url);
        this.statuses = data.data;
        // this.statuses = data.map((item) => ({
        //   status: STATUSES[item.status],
        //   condition: CONDITIONS[item.condition],
        // }));
      } catch (err) {
        throw new Error(err);
      }
    },
    availableStart() {
      this.availableTimer = setInterval(() => {
        this.availableElapsedTime += 1000;
      }, 1000);
      localStorage.setItem("current", "available");
    },
    availableStop() {
      clearInterval(this.availableTimer);
    },
    availableReset() {
      this.availableElapsedTime = 0;
    },
    busyStart() {
      this.busyTimer = setInterval(() => {
        this.busyElapsedTime += 1000;
      }, 1000);
      localStorage.setItem("current", "busy");
    },
    busyStop() {
      clearInterval(this.busyTimer);
    },
    busyReset() {
      this.busyElapsedTime = 0;
    },
    notPresentStart() {
      this.notPresentTimer = setInterval(() => {
        this.notPresentElapsedTime += 1000;
      }, 1000);
      localStorage.setItem("current", "notPresent");
    },
    notPresentStop() {
      clearInterval(this.notPresentTimer);
    },
    notPresentReset() {
      this.notPresentElapsedTime = 0;
    },
    breakStart() {
      this.breakTimer = setInterval(() => {
        this.breakElapsedTime += 1000;
      }, 1000);
      localStorage.setItem("current", "break");
    },
    breakStop() {
      clearInterval(this.breakTimer);
    },
    breakReset() {
      this.breakElapsedTime = 0;
    },
    timerHandler(start, stop1, stop2, stop3) {
      start;
      stop1;
      stop2;
      stop3;
    },
    timesSum(arr, id) {
      // Date slice format
      // const date = {
      //   d: 0,
      //   h: 0,
      //   m: 0,
      //   s: 0,
      // };

      let total = arr
        .filter(
          (item) => item.status === id
        )
        .reduce((acc, current) => {
          const getDiff = (dateEnd, dateStart) => (format) => {
            if(dateEnd === "0001-01-01T00:00:00"){
              return moment(new Date().toUTCString()).diff(dateStart, format)  - 14400;
            }
            return moment(dateEnd).diff(dateStart, format);
          };
          console.log(new Date().toUTCString())
          const diff = getDiff(current.endDate, current.startDate);

          const toDateSlices = {
            d: diff("d"),
            h: diff("h"),
            m: diff("m"),
            s: diff("s"),
          };

          return acc
            ? {
                d: Number(acc.d) + Number(toDateSlices.d),
                h: Number(acc.h) + Number(toDateSlices.h),
                m: Number(acc.m) + Number(toDateSlices.m),
                s: Number(acc.s) + Number(toDateSlices.s),
              }
            : toDateSlices;
        }, null);

      const slicesToDate = moment();
      slicesToDate.set("date", 0);
      slicesToDate.set("hour", 0);
      slicesToDate.set("minute", 0);
      slicesToDate.set("second", total.s);
      console.log("bla" ,total);

      return slicesToDate;
    },
    async getLeadInfo() {
      try {
        await this.$store.dispatch("LOAD_LEADINFO", this.mobileNumber);
        this.showLeadBox = true;
      } catch (err) {
        this.showError = true;
        throw new Error(err);
      }
    },
    async sendHelpRequest() {
      try {
        const res = await axios.get("/api/Operator/RequestModerator");
        console.log(res);
      } catch (err) {
        throw new Error(err);
      }
    },
    async saveLeadInfo() {
      try {
        const req = await axios.post("/api/Leads/EditLead", {
          mobileNumber: this.$store.state.leadInfo.mobileNumber,
          nickName: this.$store.state.leadInfo.nickName,
          address: this.$store.state.leadInfo.address,
          email: this.$store.state.leadInfo.email,
          facebook: this.$store.state.leadInfo.facebook,
          residence: this.$store.state.leadInfo.residence,
          aboutMe: this.$store.state.leadInfo.aboutMe,
          // isInRetention: this.$store.state.leadInfo.isInRetention === "Yes",
          weight: this.$store.state.leadInfo.weight,
          experience: this.$store.state.leadInfo.experience,
          moneyTransferedAmount:
            this.$store.state.leadInfo.moneyTransferedAmount,
          financialCondition: this.$store.state.leadInfo.financialCondition,
          operatorId: this.$store.state.leadInfo.operatorId,
          hairColor: hairColorMap.get(this.$store.state.leadInfo.hairColor),
          callFeedBack: callFeedbackMap.get(
            this.$store.state.leadInfo.callFeedBack
          ),
          // answers: this.questionAndAnswers.map(({ answer }) => answer),
        });
        console.log(req);
      } catch (err) {
        throw new Error(err);
      }
    },
    async organizationSubmit(){
      try {
        const organization = this.organizations.find(organization => organization.name === this.organization)
        await this.$store.dispatch("ORGANIZATION_SUBMIT", organization.uniqueId);
        this.showSomeInfo = true;
      } catch (err) {
        throw new Error(err);
      }
    },
    async getQuestionsAndAnswers(type){
      try{
        await this.$store.dispatch("LOAD_QUESTIONSANDANSWERS", type);
        this.$store.commit("SET_TYPE", type);
        this.closeBtn();
        localStorage.setItem("type", type);
      }catch(err){
        throw new Error(err);
      }
    },
  },
  async created() {
    try {
      const curr = localStorage.getItem("current");
      console.log("traki", localStorage.getItem("current"));
      console.log("traki")
      if(curr ==="available"){
        this.availableStart();
      }else if(curr ==="busy"){
        this.busyStart();
      }else if(curr === "notPresent"){
        this.notPresentStart();
      }else if(curr === "break"){
        this.breakStart();
      }
      const res = await axios.get("/api/Operator/OperatorStatusesToSwitch");
      this.statuses = res.data;
      const response = await axios.get("/api/Account/UserStatusDurations");
      this.timesArr = response.data;
      this.breakElapsedTime = this.timesSum(this.timesArr, 3);
      this.availableElapsedTime = this.timesSum(this.timesArr, 1);
      this.busyElapsedTime = this.timesSum(this.timesArr, 2);
      this.notPresentElapsedTime = this.timesSum(this.timesArr, 4);

    } catch (err) {
      throw new Error(err);
    }
  },
  watch: {
    sum: function (val) {
      if (
        val < this.$store.state.leadInfo.defaultSum ||
        val > this.$store.state.leadInfo.specialSum
      ) {
        this.sum = "Incorrect Sum!";
      }
    },
  },
  async beforeCreate(){
    try{
      await this.$store.dispatch("LOAD_WORKSCHEDULE")
    }catch(err){
      throw new Error(err);
    }
  }
};
</script>

<style scoped>
.main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-container {
  width: 75%;
  display: flex;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid black;
  margin-top: 36px;
  cursor: pointer;
  position: relative;
}

.circle:not(.active) {
  opacity: 0.1;
  pointer-events: none;
}

.green {
  margin-top: 140px;
  background: radial-gradient(50% 50% at 50% 50%, #9cd0b4 6.77%, #0d4728 100%);
}

.yellow {
  background: radial-gradient(50% 50% at 50% 50%, #ded1af 6.77%, #b6821e 100%);
}

.red {
  background: radial-gradient(50% 50% at 50% 50%, #ffc2c2 6.77%, #ae2626 100%);
}

.blue {
  background: radial-gradient(50% 50% at 50% 50%, #b7dae9 6.77%, #0c3d58 100%);
}

.options {
  width: 268px;
  height: 48px;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  background: #2e3335;
  position: absolute;
  left: 78px;
  top: 6px;
  color: #7d8182;
  display: flex;
  align-items: center;
  padding-left: 10px;
  opacity: 0;
  pointer-events: none;
}

.circle:hover .options {
  opacity: 100%;
}

#lid-box:hover .options {
  opacity: 100%;
}

#operator-box:hover .options {
  opacity: 100%;
}

.col {
  width: 96px;
  height: 100%;
  background: #2e3335;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-title {
  position: absolute;
  left: 70px;
  top: 0px;
}
.payDay {
  background: #000000;
  border: 2px solid #4273BC;
  box-sizing: border-box;
  border-radius: 10px;
  color:white;
  width: 80px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:60px;
  cursor:pointer;
  position: relative;
}
.payModal {
  position:absolute;
  width:200px;
  height:200px;
  background-color:#1B1B1C;
  left:360px;
  bottom:220px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}
.pay {
  border: 1px solid #42BC73;
  box-sizing: border-box;
  border-radius: 4px;
  width:100px;
  height:30px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:14px;
  cursor:pointer;
  color:white;
}
.editpay {
  border: 1px solid #4273BC;
  box-sizing: border-box;
  border-radius: 4px;
  width:100px;
  height:30px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:10px;
  cursor:pointer;
  color:white;
}
input {
  width:150px;
}
.modalClsBtn {
  width:100%;
  height:30px;
  align-items: center;
  justify-content: flex-end;
  background-color:#090909;
  display:flex;
  cursor:pointer;
}
.calendar {
  margin-top:10px;
  display: flex;
  flex-direction: column;
  align-items:center;
}
.submit {
  margin-top:10px;
  border: 1px solid #4273BC;
  box-sizing: border-box;
  border-radius: 4px;
  width:100px;
  height:30px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor:pointer;
  color:white;
}
svg {
  padding-right:5px;
  cursor:pointer;
}
#lid-box {
  margin: 80px 0 36px;
  position: relative;
  cursor: pointer;
}

#operator-box {
  position: relative;
  cursor: pointer;
}

.time {
  color: white;
  display: grid;
  position: absolute;
  left: 120px;
  margin-top: 160px;
  grid-column: 1;
  gap: 85px;
}

.help-request {
  position: absolute;
  bottom: 120px;
  right: 240px;
  height: 36px;
  display: flex;
  align-items: center;
  color: #7d8182;
  size: 16px;
  cursor: pointer;
}
.help-request svg {
  margin-left: 10px;
}
.getLead {
  display: flex;
}
.telInput {
  position: absolute;
  bottom: 120px;
  margin-left: 24px;
  width: 250px;
  height: 28px;
  background: #2e3335;
  outline: none;
  border: none;
  font-size: 20px;
  padding: 5px 10px;
  color: #7d8182;
}

.getLeadBtn {
  position: absolute;
  bottom: 120px;
  margin-left: 300px;
  width: 100px;
  height: 37px;
  background: #40785a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.err-message {
  width: 500px;
  height: 300px;
  background-color: #0e0e0f;
  color: #7d8182;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

.err-modal-header {
  height: 40px;
  background-color: #1b1b1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  font-size: 24px;
}

.lead-input {
  background-color: #0e0e0f;
  color: #c4c8df;
  outline: none;
  border: none;
  font-size: 20px;
  width: 250px;
  margin-left: 5px;
}

.about-me {
  display: flex;
  align-items: center;
}

.weight-input {
  width: 35px;
}

.questions {
  width: 400px;
  height: 200px;
  background: #232424;
  border: 2px solid white;
  margin-top: 10px;
  overflow-y: auto;
}

.save-lead-info {
  width: 150px;
  height: 40px;
  background: darkgreen;
  position: absolute;
  bottom: 40px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.info-container {
  width: 500px;
}
.info-container-1{
  margin-left: 60px;
}

.questions-header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.quetions-answers {
  list-style: none;
}

.answer {
  background: none;
  outline: none;
  border: none;
  color: #c4c8df;
  font-size: 20px;
  padding-left: 38px;
}

.select {
  background: none;
  color: aliceblue;
  height: 25px;
  font-size: 18px;
  padding-left: 8px;
  margin-left: 8px;
}

.select option {
  background: black;
}

.org-input {
  height: 36px;
  width: 200px;
  background: #3d3d3b;
  outline: none;
  border: none;
  padding: 0 10px;
  color: #c4c8df;
  font-size: 20px;
}

.organizations{
  margin-top: 15px;
  height: 40px;
  display: flex;
  align-items: center;
}

.org-btn{
   height: 36px;
   margin-left: 12px;
   background: darkgreen;
   color: #c4c8df;
   border: none;
   cursor: pointer;
}

.select-org{
  height: 36px;
}

.title{
  margin: 15px;
  font-size: 30px;
}

textarea{
  margin-left: 8px;
}

.questions-container{
  margin-top: 25px;
}

.grid{
  display: grid;
  grid-template-columns: auto auto;
}
</style>