<template>
    <div class="box">
    <div class="container">
      <div class="modal">
        <div class="close-btn" @click="handleCloseBtnClick">X</div>
            <div class="question">
                <label>Question:</label>
            <input v-model="question"/>
            </div>
            <div class="question">
                <label>Answer:</label>
            <select v-model="answer">
                <option>true</option>
                <option>false</option>
            </select>
            </div>
            <div @click="save" class="btn">Save</div>
        </div>
      
      </div>    
    </div>
</template>

<script>
import axios from "@/utils/axiosInstance";
export default {
  name: "QuestionsAndAnswers",
  props: {
      handleCloseBtnClick: {
          type: Function,
          required: true
      },
      closeModal: {
           type: Function,
          required: true
      }
  },
  data() {
    return {
        question: null,
        answer : null
    };
  },
  methods: {
      async save(){
          const res = await axios.post("/api/Questions/AddQuestion", {question: this.question, isAnswerYes: (this.answer === "true"), type: localStorage.getItem("type")}); 
          console.log(res);

          this.$store.dispatch("LOAD_QUESTIONSANDANSWERS", localStorage.getItem("type"));
          this.closeModal();
      }
  }
  
  
};
</script>

<style scoped>
.box{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top:0;
    left: 0;
    z-index: 5;
}
.container{
    display: flex;
    justify-content: center;
    height: 100vh;
}
.modal{
    width: 800px;
    height: 500px;
    background-color: #0E0E0F;
    color: #C4C8DF;
    background-color: #2e3335;
    position: relative;
    padding: 15px;
    font-size: 20px;
}

.close-btn{
    width: 100%;
    display: flex;
    justify-content: end;
    cursor: pointer;
    font-size: 25px;
}
input{
    width: 350px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    font-size: 20px;
}
select{
    width: 355px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    font-size: 20px;
}
.question{
    margin: 60px 0 60px 50px;
    width: 400px;
}

.btn{
    width: 160px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 25px;
    cursor: pointer;
    margin-bottom: 18px;
    margin-left: 300px;
}
</style>