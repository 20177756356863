<template>
  <div class="box"
    id="operatorInfo">
    <div class="container">
      <div class="modal">
        <div class="infos">
      <label>UserName</label>
      <input
        class="input"
        type="text"
        name="aboutOperator"
        placeholder="About operator"
        v-model="this.$store.state.operatorInfo.userName"
      /> </div>
      <div class="infos">
      <label>FullName</label>
      <input
        class="input"
        type="text"
        name="aboutOperator"
        placeholder="About operator"
        v-model="this.$store.state.operatorInfo.fullName"
      /></div>
      <div class="infos">
      <label>Mobile Number</label>
      <input
        class="input"
        type="text"
        name="aboutOperator"
        placeholder="About operator"
        v-model="this.$store.state.operatorInfo.mobileNumber"
      /></div>
      <div class="infos">
      <label>Email</label>
      <input
        class="input"
        type="text"
        name="aboutOperator"
        placeholder="About operator"
        v-model="this.$store.state.operatorInfo.email"
      /></div>
      <div class="infos">
      <label>Residence</label>
      <input
        class="input"
        type="text"
        name="aboutOperator"
        placeholder="About operator"
        v-model="this.$store.state.operatorInfo.residence"
      /></div>
      <div class="infos">
        <div>Role</div>
        <div class="input">{{this.$store.state.operatorInfo.role}}</div>
      </div>
      <div class="infos">
        <div>Accent</div>
        <div class="input">{{this.$store.state.operatorInfo.accent}}</div>
      </div>
      <div class="infos">
        <div>Gender</div>
        <div class="input">{{this.$store.state.operatorInfo.gender}}</div>
      </div>
      <div class="infos">
        <div>Data Of Birth</div>
        <div class="input">{{this.$store.state.operatorInfo.dataOfBirth}}</div>
      </div>
      <div class="infos">
        <div>Create Date</div>
        <div class="input">{{this.$store.state.operatorInfo.createDate}}</div>
      </div>
      <div class="saveBtn" @click="saveProfileInfo">
        <span>Save info</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
        >
          <circle cx="18" cy="18" r="18" fill="#45897D" />
          <path
            d="M23 9H11C9.89 9 9 9.9 9 11V25C9 26.1 9.89 27 11 27H25C26.1 27 27 26.1 27 25V13L23 9ZM25 25H11V11H22.17L25 13.83V25ZM18 18C16.34 18 15 19.34 15 21C15 22.66 16.34 24 18 24C19.66 24 21 22.66 21 21C21 19.34 19.66 18 18 18ZM12 12H21V16H12V12Z"
            fill="#141617"
          />
        </svg>
      </div>
      <div class="closeBtn" @click="closeModal">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="21"
          viewBox="0 0 376.49067 376.49067"
          width="21"
        >
          <path
            d="m316.457031 376.46875c-9.554687 0-19.132812-3.648438-26.429687-10.921875l-101.78125-101.761719-101.761719 101.761719c-14.613281 14.589844-38.3125 14.589844-52.90625 0l-22.632813-22.636719c-14.59375-14.589844-14.59375-38.3125 0-52.90625l101.757813-101.757812-101.757813-101.761719c-14.59375-14.613281-14.59375-38.335937 0-52.90625l22.632813-22.632813c14.570313-14.570312 38.292969-14.59375 52.886719 0l101.78125 101.757813 101.757812-101.757813c14.636719-14.59375 38.335938-14.59375 52.90625 0l22.636719 22.632813c14.589844 14.59375 14.589844 38.316406 0 52.90625l-101.761719 101.761719 101.761719 101.757812c14.589844 14.59375 14.589844 38.316406 0 52.90625l-22.636719 22.636719c-7.292968 7.273437-16.871094 10.921875-26.453125 10.921875zm-128.210937-151.316406c4.246094 0 8.320312 1.683594 11.304687 4.691406l113.066407 113.066406c2.09375 2.070313 5.589843 2.070313 7.660156 0l22.632812-22.632812c2.09375-2.070313 2.070313-5.589844 0-7.679688l-113.066406-113.046875c-6.25-6.25-6.25-16.382812 0-22.632812l113.066406-113.066407c2.070313-2.070312 2.09375-5.589843 0-7.660156l-22.632812-22.613281c-2.089844-2.070313-5.566406-2.070313-7.679688 0l-113.046875 113.066406c-5.992187 5.996094-16.617187 5.996094-22.632812 0l-113.066407-113.066406c-2.089843-2.070313-5.589843-2.070313-7.660156 0l-22.613281 22.636719c-2.089844 2.066406-2.070313 5.585937 0 7.679687l113.066406 113.042969c6.25 6.25 6.25 16.386719 0 22.636719l-113.066406 113.066406c-2.070313 2.070313-2.089844 5.589844 0 7.65625l22.636719 22.636719c2.089844 2.070312 5.566406 2.070312 7.679687 0l113.042969-113.066406c2.988281-3.03125 7.0625-4.714844 11.308594-4.714844zm0 0"
          />
        </svg>
      </div>
      </div>    
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    }
  },
  methods: {
    async saveProfileInfo(){
      try {
        this.$store.commit('SET_USERNAME', this.userName)
        this.$store.commit('SET_FULLNAME', this.fullName)
        this.$store.commit('SET_MOBILENUMBER', this.mobileNumber)
        this.$store.commit('SET_EMAIL', this.email)
        this.$store.commit('SET_RESIDENCE', this.residence)
        this.$store.commit('SET_ROLE', this.role)
        this.$store.commit('SET_ACCENT', this.accent)
        this.$store.commit('SET_GENDER', this.gender)
        this.$store.commit('SET_DATAOFBIRTH', this.dataOfBirth)
        this.$store.commit('SET_CREATEDATE', this.createDate)
        await this.$store.dispatch('SAVEOPERATORINFO', {fullName: this.fullName, userName: this.userName, mobileNumber: this.mobileNumber, email: this.email, residence: this.residence})
      } catch (err) {
        throw new Error(err);
      }
    }
  },
};
</script>

<style scoped>
.box{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top:0;
    left: 0;
    z-index: 5;
}
.container{
    display: flex;
    justify-content: center;
    height: 100vh;
}
.modal{
    width: 800px;
    height: 500px;
    background-color: #0E0E0F;
    color: #C4C8DF;
    background-color: #2e3335;
    position: relative;
    padding: 15px;
}

.input {
  width: 500px;
  height: 40px;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #141617;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.saveBtn {
  display: flex;
  color: #c8c4df;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.saveBtn svg {
  margin-left: 10px;
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 17px;
  cursor: pointer;
}

.infos{
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>