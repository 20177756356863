import axios from '@/utils/axiosInstance'
import moment from "moment";
import { CALL_FEEDBACK, HAIR_COLOR } from '../../constants'

const leadInfo = {
    state: {
        src: "",
        documents: "",
        fullNameL: "",
        idNumber: "",
        age: 0,
        mobileNumberL: "",
        emailL: "",
        address: "",
        residenceL: "",
        facebook: "",
        aboutMe: "",
        height: 0,
        weight: 0,
        nationality: "",
        accentL: "",
        profession: null,
        experience: "",
        moneyTransferedAmount: 0,
        isCalled: false,
        financialCondition: 0,
        operatorId: 0,
        genderL: 1,
        callFeedBack: 1,
        eyeColor: 1,
        hairColor: 1,
        callDate: "",
        nickName: "",
        dateOfBirthL: "",
        defaultSum: 0,
        specialSum: 0,
        questionAndAnswers: null,
        organizations: null,
        types: null
    },
    mutations: {
        SET_SRC(state, data) {
            state.src = data;
        },
        SET_DOCUMENTS(state, data) {
            state.documents = data;
        },
        SET_FULLNAMEL(state, data) {
            state.fullNameL = data;
        },
        SET_IDNUMBER(state, data) {
            state.idNumber = data;
        },
        SET_AGE(state, data) {
            state.age = data;
        },
        SET_MOBILENUMBERL(state, data) {
            state.mobileNumberL = data;
        },
        SET_EMAILL(state, data) {
            state.emailL = data;
        },
        SET_ADDRESS(state, data) {
            state.address = data;
        },
        SET_RESIDENCEL(state, data) {
            state.residenceL = data;
        },
        SET_FACEBOOK(state, data) {
            state.facebook = data;
        },
        SET_ABOUTME(state, data) {
            state.aboutMe = data;
        },
        SET_CREATEDBY(state, data) {
            state.createdBy = data;
        },
        SET_HEIGHT(state, data) {
            state.height = data;
        },
        SET_WEIGHT(state, data) {
            state.weight = data;
        },
        SET_NATIONALITY(state, data) {
            state.nationality = data;
        },
        SET_ACCENTL(state, data) {
            state.accentL = data;
        },
        SET_PROFESSION(state, data) {
            state.profession = data;
        },
        SET_EXPERIENCE(state, data) {
            state.experience = data;
        },
        SET_MONEYTRANSFEREDAMOUNT(state, data) {
            state.moneyTransferedAmount = data;
        },
        SET_ISCALLED(state, data) {
            state.isCalled = data;
        },
        SET_FINANCIALCONDITION(state, data) {
            state.financialCondition = data;
        },
        SET_OPERATORID(state, data) {
            state.operatorId = data;
        },
        SET_GENDERL(state, data) {
            state.genderL = data;
        },
        SET_CALLFEESBACK(state, data) {
            state.callFeedBack = data;
        },
        SET_EYECOLOR(state, data) {
            state.eyeColor = data;
        },
        SET_HAIRCOLOR(state, data) {
            state.hairColor = data;
        },
        SET_CALLDATE(state, data) {
            state.callDate = data;
        },
        SET_NICKNAME(state, data) {
            state.nickName = data;
        },
        SET_DATEOFBIRTHL(state, data) {
            state.dateOfBirthL = data;
        },
        SET_DEFAULTSUM(state, data) {
            state.defaultSum = data;
        },
        SET_SPECIALSUM(state, data) {
            state.specialSum = data;
        },
        SET_QUESTIONSANDANSWERS(state, data) {
            state.questionAndAnswers = data;
        },
        SET_ORGANIZATIONS(state, data) {
            state.organizations = data;
        },
        SET_QUESTIONTYPES(state, data) {
            state.types = data;
        }
    },
    actions: {
        async LOAD_LEADINFO({ commit }, mobileNumber) {
            try {
                const res = await axios.get(`/api/Leads/${mobileNumber}/GetLeadByPhoneNUmber`)
                commit('SET_SRC', res.data.picture)
                commit('SET_DOCUMENTS', res.data.documents)
                commit('SET_FULLNAMEL', res.data.fullName)
                commit('SET_IDNUMBER', res.data.idNumber)
                commit('SET_AGE', moment().diff(res.data.dateOfBirth, 'years'))
                commit('SET_MOBILENUMBERL', res.data.mobileNumber)
                commit('SET_EMAILL', res.data.email)
                commit('SET_ADDRESS', res.data.address)
                commit('SET_RESIDENCEL', res.data.residence)
                commit('SET_FACEBOOK', res.data.facebook)
                commit('SET_ABOUTME', res.data.aboutMe)
                commit('SET_CREATEDBY', res.data.createdBy)
                commit('SET_HEIGHT', res.data.height)
                commit('SET_WEIGHT', res.data.weight)
                commit('SET_NATIONALITY', res.data.nationality)
                commit('SET_ACCENTL', res.data.accent)
                commit('SET_PROFESSION', res.data.profession)
                commit('SET_EXPERIENCE', res.data.experience)
                commit('SET_MONEYTRANSFEREDAMOUNT', res.data.moneyTransferedAmount)
                if (res.data.isCalled === false) {
                    commit('SET_ISCALLED', "NO")
                } else {
                    commit('SET_ISCALLED', "YES")
                }
                commit('SET_FINANCIALCONDITION', res.data.financialCondition)
                commit('SET_OPERATORID', res.data.operatorId)
                if (res.data.gender === 1) {
                    commit('SET_GENDERL', "Male")
                } else if (res.data.gender === 2) {
                    commit('SET_GENDERL', "Female")
                }
                if (res.data.callFeedBack === CALL_FEEDBACK.NO_ANSWER) {
                    commit('SET_CALLFEESBACK', "No Answer")
                } else if (res.data.callFeedBack === CALL_FEEDBACK.ANSWERED_BUT_NOT_INTERESTED) {
                    commit('SET_CALLFEESBACK', "Answered But Not Interested")
                } else if (res.data.callFeedBack === CALL_FEEDBACK.INTERESTED_BUT_NOT_AGREED) {
                    commit('SET_CALLFEESBACK', "Interested But Not Agreed")
                } else if (res.data.callFeedBack === CALL_FEEDBACK.ANSWERED_AND_PAID) {
                    commit('SET_CALLFEESBACK', "Answered And Paid")
                } else if (res.data.callFeedBack === CALL_FEEDBACK.ANSWERED_AND_PAID_TO_RETENTION) {
                    commit('SET_CALLFEESBACK', "Answered And Paid To Retention")
                } else if (res.data.callFeedBack === CALL_FEEDBACK.REAL_LEAD) {
                    commit('SET_CALLFEESBACK', "Real Lead")
                }
                if (res.data.eyeColor === 1) {
                    commit('SET_EYECOLOR', "Black")
                } else if (res.data.eyeColor === 2) {
                    commit('SET_EYECOLOR', "Brown")
                } else if (res.data.eyeColor === 2) {
                    commit('SET_EYECOLOR', "Blonde")
                }
                if (res.data.hairColor === HAIR_COLOR.BLACK) {
                    commit('SET_HAIRCOLOR', "Black")
                } else if (res.data.hairColor === HAIR_COLOR.GREEN) {
                    commit('SET_HAIRCOLOR', "Green")
                } else if (res.data.hairColor === HAIR_COLOR.BROWN) {
                    commit('SET_HAIRCOLOR', "Brown")
                }
                commit('SET_CALLDATE', moment(res.data.callDate).format('MMMM Do YYYY, h:mm:ss a'))
                commit('SET_NICKNAME', res.data.nickName)
                commit('SET_DATEOFBIRTHL', moment(res.data.dateOfBirth).format("MMM Do YYYY"))
                commit('SET_ORGANIZATIONS', res.data.organizations);
                commit('SET_QUESTIONTYPES', res.data.types);
            } catch (err) {
                throw new Error(err);
            }
        },
        async ORGANIZATION_SUBMIT({ commit }, organizationId) {
            try {
                const res = await axios.get(`/api/Leads/${organizationId}/GetLeadWithOrganization`)
                commit('SET_QUESTIONSANDANSWERS', res.data.questionAndAnswers.questionAndAnswers)
                commit('SET_DEFAULTSUM', res.data.defaultSum)
                commit('SET_SPECIALSUM', res.data.specialSum)
            } catch (err) {
                throw new Error(err);
            }
        }
    },
    getters: {},
}

export default leadInfo;