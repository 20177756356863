import axios from '@/utils/axiosInstance'


const questionsNAnswers = {
    state: {
        questionsAndAnswers: null,
        type: null
    },
    mutations: {
        SET_QUESTIONANDANSWERS(state, data) {
            state.questionsAndAnswers = data;
        },
        SET_TYPE(state, data) {
            state.type = data;
        }
    },
    actions: {
        async LOAD_QUESTIONSANDANSWERS({ commit }, type) {
            try {
                const res = await axios.get(`/api/Questions/QuestionsAndAnswers?type=${type}`);
                commit('SET_QUESTIONANDANSWERS', res.data.questionsAndAnswers);
            } catch (err) {
                throw new Error(err);
            }
        },
    },
}

export default questionsNAnswers;