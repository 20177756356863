import axios from '@/utils/axiosInstance'

const user = {
    state: {
        bearerToken: "",
        refreshToken: "",
        role: "",
    },
    mutations: {
        SET_BEARERTOKEN(state, token) {
            state.bearerToken = token;
            console.log('bearerToken', state.bearerToken)
        },
        SET_REFRESHTOKEN(state, token) {
            state.refreshToken = token;
        },
        SET_ROLE(state, data) {
            state.role = data;
        }
    },
    actions: {
        async LOAD_TOKEN({ commit }, input) {
            try {
                const res = await axios.post("/api/Account/Login", input);
                commit('SET_BEARERTOKEN', res.data.bearerToken)
                commit('SET_REFRESHTOKEN', res.data.refreshToken)
                    // this.$cookies.set('bearerToken', res.bearerToken)
                    // this.$cookies.set('refreshToken', res.refreshToken)
                window.localStorage.setItem("bearerToken", res.data.bearerToken);
                window.localStorage.setItem("refreshToken", res.data.refreshToken);
            } catch (err) {
                throw new Error(err);
            }
        },
        async GET_ROLE({ commit }) {
            try {
                const res = await axios.get("/api/Account/GetUserInfo");
                commit("SET_ROLE", res.data.role)
            } catch (err) {
                throw new Error(err)
            }
        },
        TRY_LOGIN(context) {
            const bearerToken = localStorage.getItem('bearerToken');
            if (bearerToken) {
                context.commit('SET_BEARERTOKEN', bearerToken)
            }
        },
        LOGOUT(context) {
            window.localStorage.removeItem('bearerToken');
            window.localStorage.removeItem('refreshToken')

            context.commit('SET_BEARERTOKEN', null);
            context.commit('SET_REFRESHTOKEN', null);
            context.commit('SET_ROLE', null);
        }
    },
    getters: {},
    //  namespaced: true,
}

export default user;