<template>
  <div class="box">
      <div class="container">
          <div class="modal">
              <div class="modal-header">
                 <h2>Lead Information</h2>
                 <div @click="closeBtn" class="clsBtn">X</div>
              </div>
              <div class="info">
               <img :src="this.$store.state.leadInfo.src" class="pic"/>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
        }
    },
    props: ["closeBtn"]
}
</script>

<style> 
.box{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top:0;
    left: 0;
    z-index: 5;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.modal{
    width: 1200px;
    height: 900px;
    background-color: #0E0E0F;
    color: #C4C8DF;
    position: relative;
}

.modal-header{
    height: 70px;
    background-color:#1B1B1C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    font-size: 24px;
}
.info{
    margin: 5px 20px;
    font-size: 20px;
    display: flex;
    width: 85%;
    justify-content: space-between;
}
.pic{
    width:200px;
    height: 200px;
}

.info-container{
    line-height: 25px;
}

.clsBtn{
    cursor: pointer;
}
</style>