import Vue from 'vue'
import App from './App.vue'
import { store } from "@/store/store.js"
import moment from 'moment'
import VueMoment from 'vue-moment'
import axios from "@/utils/axiosInstance"
import VueCookies from 'vue-cookies'
import moderatorHub from './ModeratorHub.js'

Vue.config.productionTip = false
Vue.use(VueCookies);
Vue.use(moderatorHub)

function getLocalBearerToken() {
    const bearerToken = window.localStorage.getItem("bearerToken");
    return bearerToken;
}

function getLocalRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken;
}

axios.interceptors.request.use(function(config) {
        config.headers.Authorization = `bearer ${getLocalBearerToken()}`;
        return config;
    }, function(error) {
        return Promise.reject(error);
    }

)
axios.interceptors.response.use(
    response => response,
    async(error) => {
        if (error.response.data === "Invalid Credentials") {
            alert("Incorrect Username or Password!")
        }
        if (error.response.status !== 401 || error.response.data === "Invalid Credentials") {
            return Promise.reject(error);
        }
        axios.interceptors.response.eject();

        return axios.post(`/api/Account/RefreshToken?refreshToken=${getLocalRefreshToken()}`, {}).then(response => {
            store.commit('SET_BEARERTOKEN', response.data.bearerToken),
                store.commit('SET_REFRESHTOKEN', response.data.refreshToken)
            window.localStorage.setItem("bearerToken", response.data.bearerToken);
            window.localStorage.setItem("refreshToken", response.data.refreshToken);

            error.response.config.headers['Authorization'] = `bearer ${response.data.bearerToken}`
            return axios(error.response.config)
        }).catch(error => {
            return Promise.reject(error);
        })
    }
)

new Vue({
    render: h => h(App),
    store,
    moment,
    VueMoment,
}).$mount('#app')