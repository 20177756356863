<template>
    <div class="box">
    <div class="container">
      <div class="modal">
        <div class="close-btn" @click="handleCloseBtnClick">X</div>
        <div class="edit-btn" @click="edit">Edit</div>
        <div class="add-btn" @click="showAddquestion">Add Question</div>
        <div class="display-none confirm" @click="confirm">Confirm</div>
        <div class="ques-container" v-for="(questionAnswer, index) in questionsAndAnswers" :key="questionAnswer.question">
            <div>Q{{index + 1}} {{questionAnswer.question}}</div>
            <div class="answer">{{questionAnswer.isAnswersYes}}</div>
            <select class="display-none">
                <option>{{questionAnswer.isAnswersYes === true? "true" : "false"}}</option>
                <option>{{questionAnswer.isAnswersYes === true? "false" : "true"}}</option>
            </select>
        </div>
      
      </div>    
    </div>
  </div>
</template>

<script>
import axios from "@/utils/axiosInstance";
export default {
  name: "QuestionsAndAnswers",
  props: {
      handleCloseBtnClick: {
          type: Function,
          required: true
      },
      showAddquestion: {
          type: Function,
          required: true
      }
  },
  data() {
    return {
     inputArr: [],
     index: null,
     zeroOneArr: [],
     newQuestNAns: []
    };
  },
  computed: {
      questionsAndAnswers(){
        return  this.$store.state.questionsNAnswers.questionsAndAnswers;
      }
  },
  methods: {
      edit(){
          document.querySelectorAll("select").forEach(i => i.style.display = "block");
          document.querySelectorAll(".answer").forEach(i => i.style.display = "none");
          document.querySelectorAll(".confirm").forEach(i => i.style.display = "flex");
          document.querySelectorAll(".edit-btn").forEach(i => i.style.display = "none");         
      },
      async confirm(){
          document.querySelectorAll("select").forEach(i => i.style.display = "none");
          document.querySelectorAll(".answer").forEach(i => i.style.display = "block");
          document.querySelectorAll(".confirm").forEach(i => i.style.display = "none");
          document.querySelectorAll(".edit-btn").forEach(i => i.style.display = "flex");   
          document.querySelectorAll("select").forEach(i => this.inputArr.push(i.value));

         for(let i = 0; i < this.inputArr.length; i++){
             if(this.inputArr[i] !== String(this.questionsAndAnswers[i].isAnswersYes)){
                 this.index = i;                
             }
         } 
        
        this.questionsAndAnswers.map((el, index) => { if(index <= this.index){this.newQuestNAns.push(el.isAnswersYes)}})
        this.newQuestNAns[this.index] = (this.inputArr[this.index] === "true");
        this.newQuestNAns.map(el => { if(el == true){
            this.zeroOneArr.push(1);
        }else {this.zeroOneArr.push(0)}})


        const res = await axios.post("/api/Questions/EditAnswers", {type: localStorage.getItem("type"), answers: this.zeroOneArr});
        console.log(res);

        this.$store.dispatch("LOAD_QUESTIONSANDANSWERS", localStorage.getItem("type"));
      },
  },
};
</script>

<style scoped>
.box{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top:0;
    left: 0;
    z-index: 5;
}
.container{
    display: flex;
    justify-content: center;
    height: 100vh;
}
.modal{
    width: 800px;
    height: 500px;
    background-color: #0E0E0F;
    color: #C4C8DF;
    background-color: #2e3335;
    position: relative;
    padding: 15px;
    font-size: 20px;
}

.close-btn{
    width: 100%;
    display: flex;
    justify-content: end;
    cursor: pointer;
    font-size: 25px;
}
.ques-container{
    margin-bottom: 10px;
}

.edit-btn{
    width: 100px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 25px;
    cursor: pointer;
    margin-bottom: 18px;
}
.display-none{
    display: none;
}
.confirm{
        width: 100px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    /* display: flex; */
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 25px;
    cursor: pointer;
    margin-bottom: 18px;
}

.add-btn{
    width: 160px;
    height: 40px;
    background-color: rgb(167, 151, 151);
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 25px;
    cursor: pointer;
    margin-bottom: 18px;
}
</style>