<template>
  <div>
    <Header
      :isAuthorized="isAuthorized"
      :title="title"
      :onLogutButtonClick="handleClickLogoutButton"
      :onProfileButtonClick="handleClickProfileButton"
      :handleFullReport="handleFullReport"
    />
    <Login v-if="!isAuthorized" :onClickSignInButton="signIn"/>
    <WorkSpace v-if="isAuthorized" :isModerator="isModerator" :onLidBoxButtonClick="handleClickLidBoxButton" :showQuestionsAndAnswers="showQNA" :showAddQuestType="showAddQuestType">
      <OperatorInfo :closeModal="closeProfileButton" v-if="showProfile"/>
      <LidBox v-if="showLidBox" :closeLidBox="closeLidBox"/>
      <LeadInformation v-if="showLeadInfo"/>
      <FullReport v-if="showFullReport" :closeFullReport="closeFullReport" :ownReport="ownReport" :saveInfo="saveInfo"/>
      <IndReport  v-if="showIndReport" :closeIndReport="closeIndReport" :goBack="goBack" :saveIndInfo="saveIndInfo"/>      
      <QuestionsAndAnswers v-if="showQuestionsAndAnswers" :handleCloseBtnClick="closeQuestionsAndAnswers" :showAddquestion="showAddQuestion"/>
      <AddQuestionType v-if="showAddType" :handleCloseBtnClick="closeAddType" :closeModal="closeAddType"/>
      <AddQuestion v-if="addQuestion" :handleCloseBtnClick="closeAddQuestion" :closeModal="closeAddQuestion"/>
    </WorkSpace>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Login from "@/components/Login.vue";
import WorkSpace from "@/components/WorkSpace.vue";
import OperatorInfo from "@/components/OperatorInfo.vue";
import Header from "@/components/Header.vue";
import LidBox from "@/components/LidBox.vue";
import LeadInformation from "@/components/LeadInformation.vue";
import axios from "@/utils/axiosInstance";
import FullReport from "@/components/FullReport.vue";
import IndReport from "@/components/IndReport.vue";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import QuestionsAndAnswers from "@/components/QuestionsAndAnswers.vue";
import AddQuestionType from "@/components/AddQuestionType.vue";
import AddQuestion from "@/components/AddQuestion.vue";

export default {
  components: { Header, Footer, Login, WorkSpace, OperatorInfo, LidBox, LeadInformation, FullReport, IndReport, QuestionsAndAnswers, AddQuestionType, AddQuestion},
  data() {
    return {
      isAuthorized: false,
      showProfile: false,
      isModerator: false,
      showLidBox: false,
      showLeadInfo: false,
      showFullReport:false,
      showIndReport: false,
      showQuestionsAndAnswers: false,
      showAddType: false,
      addQuestion: false,
    };
  },
  computed: {
    title() {
      return this.isAuthorized ? "WORK SPACE" : "Welcome";
    },
  },
  methods: {
    async handleClickLogoutButton() {
      try {
        const res = await axios.post(
          "/api/Account/LogOut",
          {},
          {
            headers: {
              Authorization: `bearer ${this.$store.state.user.bearerToken}`,
            },
          }
        );
        console.log(res);
        this.$store.dispatch("LOGOUT");
        this.isAuthorized = false;
        this.isModerator = false;
        this.$moderatorHub.$connStop();
      } catch (err) {
        throw new Error(err);
      }
    },
    handleClickProfileButton() {
      this.showProfile = true;
    },
    closeProfileButton() {
      this.showProfile = false;
    },
    handleClickLidBoxButton() {
      this.showLidBox = true;
    },
    closeLidBox() {
      this.showLidBox = false;
    },
    async signIn(input) {
      try {
        await this.$store.dispatch("LOAD_TOKEN", input);
        await this.$store.dispatch("GET_ROLE");
        if (this.$store.state.user.role === "Moderator") {
          this.isModerator = true;
        }
        this.isAuthorized = true;
        this.$moderatorHub.$connStart();
      } catch (err) {
        throw new Error(err);
      }
    },
    handleFullReport(){
      if(!this.isModerator){
        this.showIndReport = true;
      }else{
        this.showFullReport = true;
      }
    },
    closeIndReport() {
      this.showIndReport = false;
    },
    closeFullReport(){
      this.showFullReport = false;
    },
    goBack() {
      this.showFullReport = false;
      this.showIndReport = false;
    },
    saveInfo() {
      this.showFullReport = false;
    },
    saveIndInfo() {
        window.html2canvas = html2canvas;
        var doc = new jsPDF("p","pt","a4");
        doc.html(document.querySelector("#indContent"),{
          callback: function(pdf) {
            pdf.save("indReport.pdf");
          }
        })
        this.showIndReport = false;
      },
    async ownReport(startDate, endDate, operatorId) {
      try {
        await this.$store.dispatch('GET_INDREPORTINFO',startDate, endDate, operatorId)
        this.showIndReport = true;
        this.showFullReport = false;
      } catch (err) {
        throw new Error(err);
      }

    },
    showQNA(){
      this.showQuestionsAndAnswers = true;
    },
    closeQuestionsAndAnswers(){
      this.showQuestionsAndAnswers = false;
    },
    showAddQuestType(){
      this.showAddType = true;
    },
    closeAddType(){
      this.showAddType = false;
    },
    showAddQuestion(){
      this.addQuestion = true;
    },
    closeAddQuestion(){
      this.addQuestion = false;
    }
  },
  created(){
    this.$store.dispatch('TRY_LOGIN');
    if(this.$store.state.user.bearerToken){
     this.isAuthorized = true;
    }
  }
};
</script>
<style>
* {
  margin: 0;
}
body {
  background: #141617;
  height: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}
</style>
