export const HAIR_COLOR = {
    BLACK: 1,
    GREEN: 2,
    BROWN: 3,
}

export const CALL_FEEDBACK = {
    NO_ANSWER: 1,
    ANSWERED_BUT_NOT_INTERESTED: 2,
    INTERESTED_BUT_NOT_AGREED: 3,
    ANSWERED_AND_PAID: 4,
    ANSWERED_AND_PAID_TO_RETENTION: 5,
    REAL_LEAD: 6
}

export const hairColorMap = new Map([
    ['Black', HAIR_COLOR.BLACK],
    ['Green', HAIR_COLOR.GREEN],
    ['Brown', HAIR_COLOR.BROWN],
])

export const callFeedbackMap = new Map([
    ['No Answer', CALL_FEEDBACK.NO_ANSWER],
    ['Answered But Not Interested', CALL_FEEDBACK.ANSWERED_BUT_NOT_INTERESTED],
    ['Interested But Not Agreed', CALL_FEEDBACK.INTERESTED_BUT_NOT_AGREED],
    ['Answered And Paid', CALL_FEEDBACK.ANSWERED_AND_PAID],
    ['Answered And Paid To Retention', CALL_FEEDBACK.ANSWERED_AND_PAID_TO_RETENTION],
    ['Real Lead', CALL_FEEDBACK.REAL_LEAD],
])