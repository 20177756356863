<template>
  <div class="content">
    <div class="mainContent">
      <select class="sort" v-model="option">
        <option value="" selected disabled hidden>Sort By</option>
        <option>userName</option>
        <option>status</option>
        <option>place</option>
      </select>
      <table class="table">
        <thead>
          <tr>
            <th>Place</th>
            <th>Operator Name</th>
            <th>Status</th>
            <th>Current Lead Number</th>
            <th>Current Oragnization</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="operator in operatorWorkSchedule"
            :key="operator.operatorId"
          >
            <td>{{ operator.place }}</td>
            <td>{{ operator.userName }}</td>
            <td>{{ checkStatus(operator.status) }}</td>
            <td>{{ operator.currLeadMobileNumber }}</td>
            <td>{{ operator.currOrganizationId }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="notification" v-show="showRequest">
      <div class="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="20"
          viewBox="0 0 17 24"
          fill="none"
        >
          <path
            d="M15.25 8C14.56 8 14 8.56 14 9.25V15H13.5C11.85 15 10.5 16.35 10.5 18H9.5C9.5 15.96 11.03 14.28 13 14.03V3.25C13 2.56 12.44 2 11.75 2C11.06 2 10.5 2.56 10.5 3.25V11H9.5V1.25C9.5 0.56 8.94 0 8.25 0C7.56 0 7 0.56 7 1.25V11H6V2.75C6 2.06 5.44 1.5 4.75 1.5C4.06 1.5 3.5 2.06 3.5 2.75V12H2.5V5.75C2.5 5.06 1.94 4.5 1.25 4.5C0.56 4.5 0 5.06 0 5.75V15.75C0 20.31 3.69 24 8.25 24C12.81 24 16.5 20.31 16.5 15.75V9.25C16.5 8.56 15.94 8 15.25 8Z"
            fill="#BC4242"
          />
        </svg>
      </div>
      <span class="text">Need Help!</span>
      <div class="number">N:{{ id }}</div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      option: "",
      id: "",
      showRequest: false,
      opId: null,
    };
  },
  created() {
    // Listen to score changes coming from SignalR events
    this.$moderatorHub.$on("need-help", this.needHelp);
    this.$moderatorHub.$on("update-table", this.updateTable);
  },
  computed: {
    operatorWorkSchedule() {
      return this.sortByKey(
        this.getArray,
        this.option
      );
    },
    getArray() {
      return this.$store.state.moderator.operatorWorkSchedule
    },
  },
  watch: {
    showRequest: function () {
      if (this.showRequest === true) {
        var self = this;
        setTimeout(function () {
          self.showRequest = false;
        }, 15000);
      }
    },
  },
  methods: {
    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];

        if (typeof x == "string") {
          x = ("" + x).toLowerCase();
        }
        if (typeof y == "string") {
          y = ("" + y).toLowerCase();
        }

        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    checkStatus(item) {
      if (item === 1) {
        return "Start Work";
      } else if (item === 2) {
        return "Busy";
      } else if (item === 3) {
        return "Break";
      } else if (item === 4) {
        return "Not Presented";
      } else {
        return "Unknown";
      }
    },
    needHelp(data) {
      console.log(data);
      this.id = data.data.operatorPlace;
      this.showRequest = true;
    },
    updateTable(dato) {
      console.log(dato)
      for(let i = 0; i < this.getArray.length; i++){
        if(dato.dato.operatorId === this.getArray[i].operatorId) {
          this.getArray.splice(i, 1);
          this.getArray.splice(i,0, dato.dato);
        }
      }
      console.log(this.getArray)
    },
  },
  beforeDestroy() {
    // Make sure to cleanup SignalR event handlers when removing the component
    this.$moderatorHub.$off("need-help", this.needHelp);
    this.$moderatorHub.$off("update-table", this.updateTable);
  },
};
</script>

<style scoped>
.content {
  width: 1440px;
  height: 650px;
  position: absolute;
  left: 18.8%;
  top: 15%;
  display: flex;
}
.mainContent {
  width: 1180px;
  height: 650px;
  color: #c4c8df;
  overflow-y: auto;
}
.table {
  width: 100%;
  border: 2px solid #3f4648;
  border-collapse: collapse;
}
.table th {
  border: 2px solid #3f4648;
  font-size: 24px;
  height: 45px;
}
.table td {
  border: 2px solid #3f4648;
  height: 30px;
  text-align: center;
}
.sort {
  background-color: transparent;
  color: #c4c8df;
  width: 120px;
  height: 25px;
  margin-bottom: 15px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
select option {
  background-color: #141617;
  cursor: pointer;
}
.notification {
  width: 220px;
  height: 50px;
  background-color: #121212;
  margin-left: 5%;
  margin-top: 3%;
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #bc4242;
  animation: mymove 3s infinite linear;
  position:absolute;
  right:150px;
  bottom:-60px;
  z-index: 200;

}
.icon {
  width: 35px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #bc4242;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4%;
}
.text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #7d8182;
  width: 130px;
  margin-left: 3%;
}
.number {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 20px;
  color: rgba(0, 230, 64, 1);
  margin-left: 10px;
  height: 20px;
  overflow: hidden;
  width: 100px;
  display: flex;
  flex-direction: row;
}




@keyframes mymove {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1.0);
  }
}
</style>