import axios from '@/utils/axiosInstance'

const reportInfo = {
    state: {
        reportInfo: null,
        indReportInfo: null,
    },
    mutations: {
        SET_REPORTINFO(state, data) {
            state.reportInfo = data;
        },
        SET_INDREPORTINFO(state, data) {
            state.indReportInfo = data;
        }
    },
    actions: {
        async GET_REPORTINFO({ commit }) {
            try {
                const res = await axios.post("/api/Report/operators-and-statuses-sum", {
                    startDate: "2021-07-01T00:00:08.142Z",
                    endDate: "2021-10-01T00:00:08.142Z",
                });
                console.log(res);
                commit('SET_REPORTINFO', res.data)
            } catch (err) {
                throw new Error(err);
            }
        },
        async GET_INDREPORTINFO({ commit }, startDate, endDate, operatorId) {
            try {
                const res = await axios.post("/api/Report/operator-statuses-by-date", {
                    operatorId: operatorId,
                    startDate: startDate,
                    endDate: endDate,
                });
                console.log(res);
                commit('SET_INDREPORTINFO', res.data)
            } catch (err) {
                throw new Error(err);
            }
        },
    },
    getters: {},
}

export default reportInfo;