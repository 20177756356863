<template>
    <div class="footer">
        <span>All rights reserved</span>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.footer {
    width: 100%;
    height: 96px;
    background: #3F4648;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A3A8A9;
    position: absolute;
    bottom: 0;

}
</style>